import React, { useState, useEffect, useContext } from "react";
import {
	Button,
	Dialog,
	makeStyles,
	DialogActions,
	DialogContent,
} from "@material-ui/core";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";


const useStyles = makeStyles((theme) => ({
	maxWidth: {
		width: "100%",
	},
	dialogPaper: {
		minWidth: '50vw',
		maxWidth: '50vw',
	},
	gridContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
}));

const Crm2Modal = ({ modalOpen, onClose, ticketid }) => {
	const [ticketCrmVinculado, setTicketCrmVinculado] = useState(null);
	const [url, setUrl] = useState('');
	const { user } = useContext(AuthContext);

	useEffect(() => {
		if (modalOpen) {
			const fetchCrmData = async () => {
				try {
					const response = await api.get(`/crm/${ticketid}`);

					if (response.data.ticket_crm_vinculado) {
						setTicketCrmVinculado(response.data.ticket_crm_vinculado.id);
					} else {
						setTicketCrmVinculado(null);
					}

					setUrl(response.data.url);
				} catch (err) {
					toastError(err);
				}
			};

			fetchCrmData();

			const handleMessage = (event) => {

				if (event.data.sucesso) {

					api.put(`/tickets/${ticketid}`, {});
					onClose();
				}
			};

			window.addEventListener("message", handleMessage);

			return () => {
				window.removeEventListener("message", handleMessage);
			};
		}
	}, [modalOpen, ticketid, onClose]);

	const handleClose = () => {
		onClose();
		if (!ticketCrmVinculado) {

		}
	};

	return (
		<div>
			{!ticketCrmVinculado && (
				<Dialog open={modalOpen} onClose={onClose} fullWidth maxWidth="lg">
					<DialogContent>
						<iframe
							src={url + "lf/sistema/crm?whatsapp=S&id_ticket=" + ticketid}
							width="100%"
							height="800px"
							style={{ border: 'none' }}
							title="Novo Card CRM"
						/>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={onClose}
							color="primary"
							variant="outlined"
						>Fechar</Button>
					</DialogActions>
				</Dialog>
			)}
			{ticketCrmVinculado && (
				<Dialog open={modalOpen} onClose={onClose} fullWidth maxWidth="lg">
					<DialogContent>
						<iframe
							src={url + "lf/sistema/crm?whatsapp=S&id_cardwhatsapp=" + ticketCrmVinculado}
							width="100%"
							height="800px"
							style={{ border: 'none' }}
							title="Card CRM"
						/>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={onClose}
							color="primary"
							variant="outlined"
						>Fechar</Button>
					</DialogActions>
				</Dialog>
			)}

		</div>
	);
};

export default Crm2Modal;