import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
	maxWidth: {
		width: "100%",
	},
	dialogPaper: {
		minWidth: '25vw',
		maxWidth: '25vw',
	},
	gridContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
}));

const filterOptions = createFilterOptions({
	trim: true,
});

const ModalBuscaChamados = ({ ModalBuscaChamadosOpen, onClose, ticketid }) => {
	const history = useHistory();
	const { user } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [selectedChamado, setSelectedChamado] = useState(null);
	const [chamados_options, setOptions] = useState([]);
	const [searchParam, setSearchParam] = useState("");

	const classes = useStyles();

	useEffect(() => {
		if (!ModalBuscaChamadosOpen || searchParam.length < 1) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchChamados = async () => {
				try {
					const { data } = await api.get("/buscachamados/", {
						params: { searchParam },
					});

					const chamadosArray = Array.isArray(data.retorno_chamados) ? data.retorno_chamados : [];

					console.log(chamadosArray);
					setOptions(chamadosArray);
					setLoading(false);
				} catch (err) {
					setLoading(false);

				}
			};

			fetchChamados();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, ModalBuscaChamadosOpen]);

	const handleClose = () => {
		onClose();
		setSearchParam("");
	};

	const handleSaveVinculoChamadoTicket = async (e) => {
		e.preventDefault();

		setLoading(true);
		try {
			let data = {};

			data.ticketid = ticketid;
			data.chamadoid = selectedChamado.id;

			const consultaexisteticketchamado = await api.get(`/consultaexisteticketchamado/${data.chamadoid}`);
			console.log(consultaexisteticketchamado.data);

			const { sucesso, msg } = consultaexisteticketchamado.data.retorno;

			if (sucesso) {
				const response = await api.post("/vincular", data);
				const { sucesso } = response.data.retorno;
				if (sucesso) {
					toast.success(i18n.t("Vínculo salvo com sucesso"));

					await api.put(`/tickets/${ticketid}`, {});

					setTimeout(() => {
						window.location.reload();
					}, 1000);
				} else {
					toast.error("Erro ao salvar vínculo");
					setLoading(false);
				}
			} else {
				toast.error(msg);
				setLoading(false);
			}

		} catch (error) {
			toast.error("Erro para vincular o Ticket ao Chamado");
			setLoading(false);
		}
	};

	return (
		<Dialog open={ModalBuscaChamadosOpen} onClose={handleClose} classes={{ paper: classes.dialogPaper }} maxWidth="lg" scroll="paper">
			<DialogTitle id="form-dialog-title">
				{i18n.t("Busca Chamados Existentes")}
			</DialogTitle>
			<hr style={{ height: '0.5px', border: 'none', backgroundColor: 'lightgrey' }} />
			<DialogContent>
				<div style={{ border: 'none', borderRadius: 15, boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '20px', backgroundColor: '#fff', marginBottom: 20 }}>
					<Grid container spacing={3} style={{ marginBottom: 20 }}>
						<Grid item xs>
							<Autocomplete
								style={{ width: '100%' }}
								getOptionLabel={(option) => `${option.id} - ${option.cliente_razao}`}
								onChange={(e, newValue) => {
									setSelectedChamado(newValue);
								}}
								options={chamados_options}
								filterOptions={filterOptions}
								value={selectedChamado || null}
								freeSolo
								autoHighlight
								noOptionsText={i18n.t("transferTicketModal.noOptions")}
								loading={loading}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Busca Chamados Existentes"
										variant="outlined"
										autoFocus
										onChange={(e) => setSearchParam(e.target.value)}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{loading ? (
														<CircularProgress color="primary" size={20} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}

									/>
								)}
							/>
						</Grid>
					</Grid>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleClose}
					color="secondary"
					disabled={loading}
					variant="outlined"
				>
					{i18n.t("Fechar")}
				</Button>
				<ButtonWithSpinner
					variant="contained"
					onClick={handleSaveVinculoChamadoTicket}
					color="primary"
					loading={loading}
				>
					{i18n.t("Vincular")}
				</ButtonWithSpinner>
			</DialogActions>
		</Dialog >
	);
};

export default ModalBuscaChamados;
